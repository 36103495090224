<template>

    <div class="inplay_content" :class="{'bg_inplay_active':active}" @click="getInplayGame(item)">
        <div class="league_info">
            <img :src="item.league.imageUrl" alt="">
            <span class="name" style="vertical-align:top;">{{item.league.leagueName}}</span>
            <span class="it" style="vertical-align:top;">{{item.startTime|datef('MM월DD일 HH:mm')}}</span>
        </div>
        <div class="bet_live">
            <div class="type_img">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER"
                     src="../../assets/images/icon/football-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL"
                     src="../../assets/images/icon/basketball-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASEBALL"
                     src="../../assets/images/icon/baseball-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL"
                     src="../../assets/images/icon/volleyball-ico.png" alt="">
                <img v-if="item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY"
                     src="../../assets/images/icon/hockey-ico.png" alt="">
            </div>
            <div class="more" @click="getInplayGame(item)">+ {{item.gameCount}}</div>
            <div class="team_info" :class="{'inplay_bg_soccer':item.apiTypeId === inplayConst.SPORTS_ID_SOCCER
                        ,'inplay_bg_volleyball':item.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL
                        ,'inplay_bg_baseball':item.apiTypeId === inplayConst.SPORTS_ID_BASEBALL
                        ,'inplay_bg_bascketball':item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL
                        ,'inplay_bg_hockey':item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY}">
                <div class="team">
                    <img alt="" :src="inplayConst.TEAM_IMAG + item.homeImgId + '.png'">
                    <span>{{item.homeTeamName}}</span>
                </div>
                <div class="score">
                    <span>{{item.homeScore}}</span>
                </div>
                <div class="timeinfo">
                    <span  style="font-size: 10px">
                         <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER">{{item.timeMm + '\''}}</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL">{{item.timeMm + ':' +
                            item.timeSs}}</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY" style="color: #f5f814">{{item.timeMm + '\''}}</i>
                    </span>
                    <span style="font-size: 10px">
                         <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER && item.halfStatus === '1'">전반</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_SOCCER && item.halfStatus === '2'">후반</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASEBALL">{{item.halfStatus}} N</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL">{{item.halfStatus}}Q</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL">{{item.halfStatus}} S</i>
                        <i v-if="item.apiTypeId === inplayConst.SPORTS_ID_ICEHOKEY" style="color: #f5f814">{{item.halfStatus}} P</i>
                    </span>
                </div>
                <div class="score">
                    <span>{{item.awayScore}}</span>
                </div>
                <div class="team">
                    <img alt="" :src="inplayConst.TEAM_IMAG + item.awayImgId + '.png'">
                    <span>{{item.awayTeamName}}</span>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import inplayConst from "../../common/inplayConst";
    import sportsConst from "../../common/sportsConst";

    export default {
        name: "InpalyGameInfo",
        props: {
            item: {
                type: Object,
                default() {
                    return null
                }
            },
            active: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        data(){
            return {
                inplayConst,
                sportsConst,
            }
        },
        methods:{
            getInplayGame(item){
                this.$emit('inplayDetail',item);
            }
        }

    }
</script>

<style scoped>
    .active_bg{
        transition: 300ms all;
        background: #f19f09!important;
    }
    .inplay_content {
        width: 100%;
        margin-bottom: 30px;
        --border: 1px solid #2d2d34;
        border-radius: 0px;
        background:var(--inplayLeagueBg);
        --box-shadow: #282828 1px 2px 4px -1px;
        transition: 300ms all;
    }

    .inplay_content .league_info {
        width: 100%;
        height: 34px;
        border-bottom: 1px solid #505050;
        background: linear-gradient(var(--inplayLeagueBg) 0%, var(--inplayLeagueBg) 100%);
        box-sizing: border-box;
        padding-left: 3px;

    }
    .inplay_content .league_info .league_img img {
        width: 38px;
        height: 27px;
    }

    .inplay_content .league_info .league_img span {
        box-sizing: border-box;
        padding-left: 10px;
        line-height: 30px
    }

    .inplay_content .league_info .stime span {
        float: right;
        padding-right: 3px
    }


    .inplay_wrap .inplay_content .bet_live .type_img {
        position: absolute;
        z-index: 10;
        left: 8px;
        top: 10px;
    }

    .inplay .inplay_content .bet_live .more {
        position: absolute;
        --z-index: 10;
        right: 3px;
        top: 3px;
        width: 38px;
        line-height: 24px;
        text-align: center;
        border-radius: 30px;
        background-color: rgb(192, 0, 0);
        color: #fff9ff;
        font-weight: bold;
        cursor: pointer;
    }

    .inplay_wrap .inplay_content .bet_live .type_img img {
        width: 22px
    }

    .inplay_wrap .inplay_content .team_info {
        width: 100%;
        height: 80px;
        background-size: cover;
        display: flex;
        flex-wrap: nowrap;
    }


    .inplay_wrap .inplay_content .team_info .team, .score, .timeinfo {
        height: 80px;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    .inplay_wrap .inplay_content .team_info .team {
        width: 34%;
    }

    .inplay_wrap .inplay_content .team_info .team .timg {

    }

    .inplay_wrap .inplay_content .team_info .team .timg img {
        width: 34px;
    }

    .inplay_wrap .inplay_content .team_info .team .tname {
        text-align: center;
        margin-top: 2px;
    }

    .inplay_wrap .inplay_content .team_info .score {
        width: 11%;
        box-sizing: border-box;
    }

    .inplay_wrap .inplay_content .team_info .score .s {
        padding: 20px 20px;
        --background-color: #f4c600;
        color: #f1f42f;
        font-weight: bold !important;
        font-size: 18px;
        border-radius: 2px;
        --border: 1px solid #a9974b;
    }

    .inplay_wrap .inplay_content .team_info .timeinfo {
        width: 10%;

    }

    .inplay_wrap .inplay_content .team_info .timeinfo .t, .hs {
        line-height: 20px;
    }

    .inplay_wrap .inplay_content .team_info .timeinfo .t {
        color: #f4c600;
        font-weight: bold;
    }

    .inplay_wrap .inplay_content .team_info .timeinfo .hs {
        color: #00bfff;
        font-weight: bold;
    }

</style>